import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import fireDb from "../firebase";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { auth } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
// const string = "abcdefghijklmnopqrstuvwxyz";
// const numeric = "0123456789";
// const punctuation = "!@#$%^&*()_+~`|}{[]:;?><,./-=";

var initialState = {
  userName: "",
  userPassword: "",
  userEmail: "",
  userFullName: "",
  userCompany: "",
  authUid: "",
};

const Users = () => {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});

  const { userName, userPassword, userEmail, userFullName, userCompany } =
    state;

  const [validuserName, setValidUserName] = useState(
    "form-control dialog-input"
  );
  const [validuserPassword, setValidUserPassword] = useState(
    "form-control dialog-input"
  );
  const [validuserEmail, setValidUserEmail] = useState(
    "form-control dialog-input"
  );
  const [validuserFullName, setValidUserFullName] = useState(
    "form-control dialog-input"
  );
  const [validuserCompany, setValidUserCompany] = useState(
    "form-control dialog-input"
  );

  // for rendom password
  // const [length, setLength] = useState(8);
  // const [password, setPassword] = useState("");

  // const navigate = useNavigate();

  const { id } = useParams();

  var adminEmail = localStorage.getItem("AdminEmail");
  var adminPassword = localStorage.getItem("AdminPassword");

  useEffect(() => {
    fireDb.child("users").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });

    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }

    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  useEffect(() => {
    fireDb.child("users").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });

    return () => {
      setData({});
    };
  }, []);

  //

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValidUserName("form-control dialog-input");
    setValidUserPassword("form-control dialog-input");
    setValidUserEmail("form-control dialog-input");
    setValidUserFullName("form-control dialog-input");
    setValidUserCompany("form-control dialog-input");
    setState({ ...state, [name]: value });
  };

  // var AdminEmail = localStorage.getItem('AdminEmail')
  // var AdminPassword= localStorage.getItem('AdminPassword')
  // if(AdminEmail!==null){
  //   signInWithEmailAndPassword(auth, AdminEmail, AdminPassword);
  // }
  // Submitting User to database

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!userName) {
        // toast.error("Please fill all fields");
        setValidUserName("invalid");
      } else if (!userPassword) {
        setValidUserPassword("invalid");
      } else if (!userEmail) {
        setValidUserEmail("invalid");
      } else if (!userFullName) {
        setValidUserFullName("invalid");
      } else if (!userCompany) {
        setValidUserCompany("invalid");
      } else {
        if (!id) {
          // creating user by Authentication
          const Result = await createUserWithEmailAndPassword(
            auth,
            userEmail,
            userPassword
          );

          //
          state.authUid = Result.user.uid;

          fireDb.child("users").push(state, (err) => {
            if (err) {
              toast.error(err);
            } else {
              toast.success("User Added Successfully");
            }
          });
          await signInWithEmailAndPassword(auth, adminEmail, adminPassword);
          // signOut(auth);
          // await signInWithEmailAndPassword(auth, adminEmail, adminPassword);
          // window.location.reload(true);
          // signOut(Result.user);
        } else {
          fireDb.child(`users/${id}`).set(state, (err) => {
            if (err) {
              toast.error(err);
            } else {
              toast.success("User Updated Successfully");
            }
          });
        }
      }
    } catch (err) {
      if (err.message === "Firebase: Error (auth/email-already-in-use).") {
        toast.error(`User Already Exists`);
      }
    }
  };

  var AllPositions = [];
  fireDb.child("positions").on(
    "value",
    (snapshot) => {
      if (snapshot.val() !== null) {
        snapshot.forEach((childSnapshot) => {
          // const childKey = childSnapshot.key;
          const childData = childSnapshot.val();

          AllPositions.push({
            userid: childData.userid,
          });
        });
      }
    },
    {
      onlyOnce: true,
    }
  );

  // For Deleting users from Database/Table Records

  const onDelete = (id) => {
    if (window.confirm("Are you sure, You want to delete that User?")) {
      var k = 0;
      for (let i = 0; i < AllPositions.length; i++) {
        if (AllPositions[i].userid === id) {
          k = 1;
        }
      }
      if (k === 1) {
        toast.error("Searches Exist Against this User");
      } else {
        fireDb.child(`users/${id}`).remove((err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("User Deleted Successfully. Please Delete User from Authentication too");
          }
        });
      }
    }

    // if (window.confirm("Are you sure, You want to delete that User?")) {
    //   fireDb.child(`users/${id}`).remove((err) => {
    //     if (err) {
    //       toast.error(err);
    //     } else {
    //       toast.success("User Deleted Successfully");
    //     }
    //   });
    // }
  };

  const emptyState = () => {
    if (!id) {
      setState("");
    }
  };

  // For counting total users record

  var totalUsers = 0;

  Object.keys(data).map((id, index) => {
    return totalUsers++;
  });

  // For Generating rendom user password

  // const generatePassword = (e) => {
  //   e.preventDefault();
  //   const formValid = +length > 0;
  //   if (!formValid) {
  //     return;
  //   }
  //   let character = "";
  //   let password = "";
  //   while (password.length < length) {
  //     const entity1 = Math.ceil(string.length * Math.random() * Math.random());
  //     const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
  //     const entity3 = Math.ceil(
  //       punctuation.length * Math.random() * Math.random()
  //     );
  //     let hold = string.charAt(entity1);
  //     hold = password.length % 2 === 0 ? hold.toUpperCase() : hold;
  //     character += hold;
  //     character += numeric.charAt(entity2);
  //     character += punctuation.charAt(entity3);
  //     password = character;
  //   }
  //   password = password
  //     .split("")
  //     .sort(() => {
  //       return 0.5 - Math.random();
  //     })
  //     .join("");
  //   setPassword(password.substr(0, length));
  // };

  //--------- HTML CODE

  // <form onSubmit={generatePassword}>
  //   <div>
  //     <label>length</label>
  //     <input value={length} onChange={(e) => setLength(e.target.value)} />
  //   </div>
  //   <button type="submit">generate password</button>
  // </form>;
  // <div>{password}</div>

  return (
    <div>
      <div className="container-fluid container-custom">
        <hr className="hr"></hr>

        <div className="row mt-2 mb-4">
          <div className="col-4">Home/Users</div>
        </div>
        <div className="row candidates-row">
          <h3 className="">Users</h3>
          <div className="col-lg-12 candidate-search-container">
            <div>
              <Link to="/">
                <button
                  className="add-candidate-button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  title="Add User"
                >
                  Add User
                </button>
              </Link>
            </div>

            <div className="col-2 text-center">
              <h2>{totalUsers}</h2>
              <p>All Users</p>
            </div>
          </div>
        </div>

        <hr className="hr"></hr>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header px-4 pt-4 pb-3 border-0">
                <h5 className="modal-title" id="exampleModalLabel">
                  {id ? "Edit User" : "Add User"}
                </h5>
                <button
                  title="Close"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={emptyState}
                ></button>
              </div>

              <div className="modal-body px-4 pt-3 pb-3">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="userName" className="form-label">
                      Name: <span>*</span>
                    </label>
                    <input
                      className={validuserName}
                      type="text"
                      id="userName"
                      name="userName"
                      placeholder="Your Name..."
                      value={userName || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="userPassword" className="form-label">
                      Password: <span>*</span>
                    </label>

                    <input
                      className={validuserPassword}
                      id="userPassword"
                      name="userPassword"
                      placeholder="Your userPassword..."
                      value={userPassword || ""}
                      onChange={handleInputChange}
                      readOnly={id ? true : false}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="userEmail" className="form-label">
                      Email: <span>*</span>
                    </label>
                    <input
                      className={validuserEmail}
                      type="email"
                      id="userEmail"
                      name="userEmail"
                      placeholder="Your userEmail..."
                      value={userEmail || ""}
                      onChange={handleInputChange}
                      readOnly={id ? true : false}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="userFullName" className="form-label">
                      Full Name: <span>*</span>
                    </label>
                    <input
                      className={validuserFullName}
                      type="text"
                      id="userFullName"
                      name="userFullName"
                      placeholder="Your Full Name..."
                      value={userFullName || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="userCompany" className="form-label">
                      Company: <span>*</span>
                    </label>
                    <input
                      className={validuserCompany}
                      type="text"
                      id="userCompany"
                      name="userCompany"
                      placeholder="Your Company..."
                      value={userCompany || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="pt-4">
                    <input
                      type="button"
                      className="btn1"
                      data-bs-dismiss="modal"
                      value="Cancel"
                      onClick={emptyState}
                    />
                    <input
                      className="btn2"
                      type="submit"
                      value={id ? "Update" : "Save"}
                    />
                  </div>
                </form>
              </div>

              <div className="modal-footer border-0"></div>
            </div>
          </div>
        </div>

        <div className="tableContainer">
          <table className="table styled-table">
            <thead>
              <tr>
                <th>User Name</th>
                <th>Email Address</th>
                <th>Company</th>
                <th>User Full Name</th>
                <th>Psssword</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(data).map((id, index) => {
                return (
                  <tr key={id}>
                    <td>{data[id].userName}</td>
                    <td>{data[id].userEmail}</td>
                    <td>{data[id].userCompany}</td>
                    <td>{data[id].userFullName}</td>
                    <td>{data[id].userPassword}</td>
                    <td>
                      <Link to={`/updateUser/${id}`}>
                        <button
                          className="btn btn-edit"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          Edit
                        </button>
                      </Link>

                      <button
                        className="btn btn-delete"
                        onClick={() => onDelete(id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Users;
