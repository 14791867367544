import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { getAuth } from "firebase/auth";


// LOCAL CONFIG

// const firebaseConfig = {
//   apiKey: "AIzaSyB8gzdv3YZfY9vaSNE1SBlIG4vMlEeQ0FI",
//   authDomain: "accelent-v2.firebaseapp.com",
//   projectId: "accelent-v2",
//   storageBucket: "accelent-v2.appspot.com",
//   messagingSenderId: "707646023273",
//   appId: "1:707646023273:web:24cedfefe7c46e9467a4f8"
// };


// NEW LIVE DB CONFIG
/*
const firebaseConfig = {
  apiKey: "AIzaSyChlJ2l8vZ2Lg9efdgPaoovNF49QsTeDTI",
  authDomain: "quadrantgraph-b5413.firebaseapp.com",
  databaseURL: "https://quadrantgraph-b5413-default-rtdb.firebaseio.com",
  projectId: "quadrantgraph-b5413",
  storageBucket: "quadrantgraph-b5413.appspot.com",
  messagingSenderId: "271291366516",
  appId: "1:271291366516:web:394d35b18c133d0359e521"
};
*/

// Jon created project configuration
const firebaseConfig = {
  apiKey: "AIzaSyD60CbX3aUbnTSchTPJoyn00cTSMnXqOF8",
  authDomain: "accelent-candidate-graph.firebaseapp.com",
  projectId: "accelent-candidate-graph",
  storageBucket: "accelent-candidate-graph.appspot.com",
  databaseURL: "https://accelent-candidate-graph-default-rtdb.firebaseio.com/",
  messagingSenderId: "410099535420",
  appId: "1:410099535420:web:3396576a2068c43d56bd39",
  measurementId: "G-PP6C4TMRC6"
};
// LIVE CONFIG

// const firebaseConfig = {
//   apiKey: "AIzaSyCKBtNkIpX2YzMQ9gHyLWUbunNITCbEnzw",
//   authDomain: "accelent-603b7.firebaseapp.com",
//   databaseURL: "https://accelent-603b7-default-rtdb.firebaseio.com",
//   projectId: "accelent-603b7",
//   storageBucket: "accelent-603b7.appspot.com",
//   messagingSenderId: "886431932342",
//   appId: "1:886431932342:web:74177bd56ddce38e69ab17"
// };

/* Development mode
// ACCELENT3 TEST DB

const firebaseConfig = {
  apiKey: "AIzaSyD9K5ahDlgJZcqiVVGa9oOwDId6By7Iz6Q",
  authDomain: "accelent3.firebaseapp.com",
  projectId: "accelent3",
  storageBucket: "accelent3.appspot.com",
  messagingSenderId: "396426903694",
  appId: "1:396426903694:web:8ed32962216b26ceeb89df",
  measurementId: "G-7RJ5KD2W9T"
};
*/

  const fireDb = firebase.initializeApp(firebaseConfig);
  export const auth = getAuth(fireDb);
  export default fireDb.database().ref()



