import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="container-fluid container-custom">
      <hr className="hr"></hr>
      <div className="errorImage">
        <img src="images/2.jpg" alt="error404"></img>
        <Link to="/">
          <button className="btn2">Go Back</button>
        </Link>
      </div>
    </div>
  );
};

export default Error;
