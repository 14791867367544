import React from "react";
import { useState, useEffect } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import fireDb from "./firebase";
import "./App.css";
import Candidates from "./pages/Candidates";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import Users from "./pages/Users";
import Positions from "./pages/Positions";

import $ from "jquery";

import { auth } from "./firebase";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  // createUserWithEmailAndPassword,
} from "firebase/auth";
import Error from "./pages/Error";

function App() {
  const [user, setUser] = useState(null);
  // const [registrationemail, setRegistrationEmail] = useState("");
  // const [registrationepassword, setRegistrationPassword] = useState("");
  // const [registrationError, setRegistrationError] = useState("");

  const [loginemail, setLoginEmail] = useState("");
  const [loginepassword, setLoginPassword] = useState("");
  // const [loginError, setLoginError] = useState("");

  // async function rigister(e) {
  //   e.preventDefault();
  //   setRegistrationError(null);
  //   try {
  //     await createUserWithEmailAndPassword(
  //       auth,
  //       registrationemail,
  //       registrationepassword
  //     );
  //   } catch (e) {
  //     setRegistrationError(e);
  //   }
  // }

  const getUsersFromFirebaseDB = () => {
    var usersOutsideOfState = null;

    fireDb.child("users").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        usersOutsideOfState = { ...snapshot.val() };
      } else {
        usersOutsideOfState = {};
      }

      console.log("All Users after fetching", usersOutsideOfState);
      Object.keys(usersOutsideOfState).map((id, index) => {
        if (usersOutsideOfState[id].userEmail === loginemail) {
          signOut(auth);
          setTimeout(() => {
            document.getElementById("validation").innerHTML = "Access Denied";
          }, 100);
        }
      });
    });
  };

  async function login(e) {
    e.preventDefault();
    var email = $("#LoginEmail").val();
    var password = $("#LoginPassword").val();
    if (email === "") {
      document.getElementById("validation").innerHTML = "Please enter name";
    } else if (password === "") {
      document.getElementById("validation").innerHTML = "Please enter password";
    } else {
      await signInWithEmailAndPassword(auth, loginemail, loginepassword)
        .then(() => {
          getUsersFromFirebaseDB();
          localStorage.setItem("AdminEmail", loginemail);
          localStorage.setItem("AdminPassword", loginepassword);
        })
        .catch((e) => {
          const errorMessage = e.message;
          if (errorMessage === "Firebase: Error (auth/user-not-found).") {
            document.getElementById("validation").innerHTML =
              "Admin does not exist";
          } else {
            document.getElementById("validation").innerHTML =
              "Invalid Admin password";
          }
        });
    }
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
  }, []);

  return (
    <div>
      {!!auth.currentUser && (
        <HashRouter>
          <div className="App">
            <Header />
            <ToastContainer position="top-center" autoClose={1000} />
            <Routes>
              <Route path="/" element={<Users />} />
              <Route path="/positions" element={<Positions />} />
              <Route path="/candidates" element={<Candidates />} />
              <Route path="/updateUser/:id" element={<Users />} />
              <Route path="/updatePositions/:id" element={<Positions />} />
              <Route path="/updateCandidate/:id" element={<Candidates />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </div>
        </HashRouter>
      )}
      {!auth.currentUser && (
        <div className="container-fluid container-styled">
          <div className="row">
            <div className="col-md-6 d-none d-sm-flex d-sm-none d-md-flex justify-content-center bg-image flex-wrap vh-100 BGColor text-white">
              <div className="align-self-center">
                <h1 className="text-center">Accelent Quadrant Graph</h1>
                <h1 className="text-center">Admin Panel</h1>
              </div>
            </div>

            <div className="col-md-6 col-sm-12 d-flex justify-content-center flex-wrap vh-100">
              <div className="align-self-center">
                <h2 className="textColor py-2 text-center mx-5 px-5">
                  Admin Login
                </h2>

                <div className=" well">
                  <div className=" row">
                    <div className="form-group well col-sm-1"></div>
                    <div className="form-group well col-sm-10 py-3">
                      <div id="AccessDenied"></div>
                      <p id="validation" className="textColor"></p>
                      <form onSubmit={login}>
                        <input
                          type="email"
                          value={loginemail}
                          id="LoginEmail"
                          className="leftBorder form-control"
                          maxLength="70"
                          placeholder="Enter Email"
                          onChange={(ev) => setLoginEmail(ev.target.value)}
                        />
                        <input
                          type="password"
                          id="LoginPassword"
                          value={loginepassword}
                          className="leftBorder form-control  my-3"
                          placeholder="Enter Password"
                          maxLength="40"
                          onChange={(ev) => setLoginPassword(ev.target.value)}
                        />
                        <button
                          className="form-control btn-block mt-3 text-white rounded-pill darker add-candidate-button"
                          type="submit"
                        >
                          L O G I N
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
