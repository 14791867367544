import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";
import logo from "../assets/accelent-logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faBuildingUser,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";

import { auth } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";

const Header = () => {
  const [activeTab, setActiveTab] = useState("Home");
  const location = useLocation();
  var user;
  const [authUser, setAuthUser] = useState("");
  useEffect(() => {
    user = localStorage.getItem("AdminEmail");
    setAuthUser(user);
  }, []);

  async function logout() {
    localStorage.removeItem("AdminEmail");
    localStorage.removeItem("AdminPassword");
    await signOut(auth);
  }

  // <div>{localStorage.getItem("AdminEmail")}</div>

  useEffect(() => {
    if (location.pathname === "/Home") {
      setActiveTab("Users");
    } else if (location.pathname === "/candidates") {
      setActiveTab("Candidates");
    } else if (location.pathname === "/positions") {
      setActiveTab("Positions");
    }
  }, [location]);

  return (
    <>
      <nav className="navbar navbar-container navbarContainerCustom">
        <div className="container-fluid">
          <Link to="/">
            <div onClick={() => setActiveTab("Home")}>
              <img className="logo-img" src={logo} alt="logo" />
            </div>
          </Link>

          <div className="text-danger candidate-icons">
            <Link to="/">
              <div
                className={`${activeTab === "Home" ? "active" : "inactive"} `}
                onClick={() => setActiveTab("Home")}
              >
                <FontAwesomeIcon
                  title="Manage Users"
                  className="m-1"
                  icon={faUsers}
                />
              </div>
            </Link>

            <Link to="/positions">
              <div
                className={`${
                  activeTab === "Positions" ? "active" : "inactive"
                } `}
                onClick={() => setActiveTab("Positions")}
              >
                <FontAwesomeIcon
                  title="Manage Searches"
                  className="m-1"
                  icon={faBuildingUser}
                />
              </div>
            </Link>

            <Link to="/candidates">
              <div
                className={`${
                  activeTab === "Candidates" ? "active" : "inactive"
                } `}
                onClick={() => setActiveTab("Candidates")}
              >
                <FontAwesomeIcon
                  title="Manage Candidates"
                  className="m-1"
                  icon={faPeopleGroup}
                />
              </div>
            </Link>
          </div>
          <div className="logout-container">
            <div>{auth.currentUser?.email}</div>
            <Link to="/">
              <div onClick={() => setActiveTab("Home")}>
                <button onClick={logout} title="Logout" className="logout">
                  Log Out
                </button>
              </div>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
