import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fireDb from "../firebase";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";

const initialState = {
  user: "",
  userid: "",
  positionName: "",
  clientCompany: "",
  competencyLable1: "",
  competencyLable2: "",
  competencyLable3: "",
};

const Positions = () => {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});
  const [users, setUsers] = useState({});

  const [validpositionName, setValidPositionName] = useState(
    "form-control dialog-input"
  );
  const [validclientCompany, setValidClientCompany] = useState(
    "form-control dialog-input"
  );
  const [validCompetencyLable1, setValidCompetencyLable1] = useState(
    "form-control dialog-input"
  );
  const [validCName, setValidCName] = useState("form-control dialog-input");

  const {
    positionName,
    clientCompany,
    competencyLable1,
    competencyLable2,
    competencyLable3,
    userid,
  } = state;

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    fireDb.child("positions").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });

    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }

    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  useEffect(() => {
    fireDb.child("positions").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });

    return () => {
      setData({});
    };
  }, []);

  //   For getting users

  useEffect(() => {
    fireDb.child("users").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setUsers({ ...snapshot.val() });
      } else {
        setUsers({});
      }
    });

    return () => {
      setUsers({});
    };
  }, [id]);

  let text, index;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValidCName("form-control dialog-input");
    setValidPositionName("form-control dialog-input");
    setValidClientCompany("form-control dialog-input");
    setValidCompetencyLable1("form-control dialog-input");
    debugger;
    index = e.nativeEvent.target.selectedIndex;
    if (index !== undefined) {
      text = e.nativeEvent.target[index].text;
      let userid = e.target.dataset.userid;
      setState({ ...state, [name]: text, [userid]: value });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    // debugger
    e.preventDefault();
    var validclientName = $("#client").val();
    if (validclientName === "Select Client") {
      setValidCName("invalid");
    } else if (!clientCompany) {
      setValidClientCompany("invalid");
    } else if (!positionName) {
      setValidPositionName("invalid");
    } else if (!competencyLable1) {
      setValidCompetencyLable1("invalid");
    } else {
      if (!id) {
        fireDb.child("positions").push(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Search Added Successfully");
          }
        });
      } else {
        fireDb.child(`positions/${id}`).set(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Search Updated Successfully");
          }
        });
      }

      setTimeout(() => navigate.push("/"), 500);
    }
  };

  var AllCandidates = [];
  fireDb.child("Candidates").on(
    "value",
    (snapshot) => {
      if (snapshot.val() !== null) {
        snapshot.forEach((childSnapshot) => {
          // const childKey = childSnapshot.key;
          const childData = childSnapshot.val();

          AllCandidates.push({
            PositionID: childData.PositionID,
          });
        });
      }
    },
    {
      onlyOnce: true,
    }
  );

  // to delete positons

  const onDelete = (id) => {
    if (window.confirm("Are you sure, You want to delete that Search?")) {
      var k = 0;
      // debugger;
      for (let i = 0; i < AllCandidates.length; i++) {
        // debugger;
        if (AllCandidates[i].PositionID === id) {
          k = 1;
        }
      }
      if (k === 1) {
        toast.error("Candidates Exist Against this Search");
      } else {
        fireDb.child(`positions/${id}`).remove((err) => {
          if (err) {
            toast.error(err);
          } else {
            toast.success("Search Deleted Successfully");
          }
        });
      }
    }
  };

  var totalPositions = 0;

  Object.keys(data).map((id, index) => {
    return totalPositions++;
  });

  return (
    <div>
      <div className="container-fluid container-custom">
        <hr className="hr"></hr>

        <div className="row mt-2 mb-4">
          <div className="col-4">Home/Searches</div>
        </div>
        <div className="row candidates-row">
          <h3 className="">Searches</h3>
          <div className="col-lg-12 candidate-search-container">
            <div className="candidate-search-container1">
              <div>
                <Link to="/positions">
                  <button
                    className="add-candidate-button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    title="Add Search"
                  >
                    Add Search
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-2 text-center">
              <h2>{totalPositions}</h2>
              <p>All Searches</p>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header px-4 pt-4 pb-3 border-0">
                <h5 className="modal-title" id="exampleModalLabel">
                  {id ? "Edit Search" : "Add Search"}
                </h5>
                <button
                  title="Close"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body px-4 pt-3 pb-3">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-md-6 mt-2">
                    <label htmlFor="client" className="form-label">
                      Client Name: <span>*</span>
                    </label>
                    <select
                      className={validCName}
                      id="client"
                      name="user"
                      data-userid="userid"
                      onChange={handleInputChange}
                    >
                      <option selected>Select Client</option>

                      {Object.keys(users).map((id, index) => {
                        if (userid === id) {
                          return (
                            <option value={id} key={id} selected>
                              {users[id].userName}
                            </option>
                          );
                        }
                        return (
                          <option value={id} key={id}>
                            {users[id].userName}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="clientCompany" className="form-label">
                      Client Company: <span>*</span>
                    </label>
                    <input
                      className={validclientCompany}
                      type="text"
                      id="clientCompany"
                      name="clientCompany"
                      placeholder="Search Name"
                      value={clientCompany || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="positionName" className="form-label">
                      Search Name: <span>*</span>
                    </label>
                    <input
                      className={validpositionName}
                      type="text"
                      id="positionName"
                      name="positionName"
                      placeholder="Search Name"
                      value={positionName || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="competencyLable1" className="form-label">
                      Competency 1: <span>*</span>
                    </label>
                    <input
                      className={validCompetencyLable1}
                      type="text"
                      id="competencyLable1"
                      name="competencyLable1"
                      placeholder="Competency 1"
                      value={competencyLable1 || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="competencyLable2" className="form-label">
                      Competency 2:
                    </label>
                    <input
                      className="form-control dialog-input"
                      type="text"
                      id="competencyLable2"
                      name="competencyLable2"
                      placeholder="Competency 2"
                      value={competencyLable2 || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mt-2">
                    <label htmlFor="competencyLable3" className="form-label">
                      Competency 3:
                    </label>
                    <input
                      className="form-control dialog-input"
                      type="text"
                      id="competencyLable3"
                      name="competencyLable3"
                      placeholder="Competency 3"
                      value={competencyLable3 || ""}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="pt-4">
                    <input
                      type="button"
                      className="btn1"
                      data-bs-dismiss="modal"
                      value="Cancel"
                    />
                    <input
                      className="btn2"
                      type="submit"
                      value={id ? "Update" : "Save"}
                    />
                  </div>
                </form>
              </div>

              <div className="modal-footer px-4 pt-3 border-0"></div>
            </div>
          </div>
        </div>

        <hr className="hr"></hr>

        <div className="tableContainer">
          <table className="table styled-table">
            <thead>
              <tr>
                <th>Client Name</th>
                <th>Client Company</th>
                <th>Search Name</th>
                <th>Competency 1</th>
                <th>Competency 2</th>
                <th>Competency 3</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(data).map((id, index) => {
                return (
                  <tr key={id}>
                    <td>{data[id].user}</td>
                    <td>{data[id].clientCompany}</td>
                    <td>{data[id].positionName}</td>
                    <td>{data[id].competencyLable1}</td>
                    <td>{data[id].competencyLable2}</td>
                    <td>{data[id].competencyLable3}</td>
                    <td>
                      <Link to={`/updatePositions/${id}`}>
                        <button
                          className="btn btn-edit"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          Edit
                        </button>
                      </Link>

                      <input
                        className="btn btn-delete"
                        type="submit"
                        onClick={() => onDelete(id)}
                        value="Delete"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Positions;
